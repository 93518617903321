/* eslint-disable */
/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleFunctions = { pattern: peg$parsepattern },
      peg$startRuleFunction  = peg$parsepattern,

      peg$c0 = peg$otherExpectation("term"),
      peg$c1 = function(body) {return body},
      peg$c2 = function(body) {
        body = parseToObject(body)
        body.type = 'group'
        return body
      },
      peg$c3 = peg$otherExpectation("group"),
      peg$c4 = "[",
      peg$c5 = peg$literalExpectation("[", false),
      peg$c6 = "]",
      peg$c7 = peg$literalExpectation("]", false),
      peg$c8 = function(value) {
        return { type:'degrade', value}
      },
      peg$c9 = function(body) { return body },
      peg$c10 = /^[0-9]/,
      peg$c11 = peg$classExpectation([["0", "9"]], false, false),
      peg$c12 = function() { return { type:'number', value:+text() }},
      peg$c13 = "*",
      peg$c14 = peg$literalExpectation("*", false),
      peg$c15 = "/",
      peg$c16 = peg$literalExpectation("/", false),
      peg$c17 = function(value, operator, repeatValue) {
        return { type:'repeat', operator, repeatValue, value }
      },
      peg$c18 = "~",
      peg$c19 = peg$literalExpectation("~", false),
      peg$c20 = function() {
       return { type:'rest' }
      },
      peg$c21 = "<",
      peg$c22 = peg$literalExpectation("<", false),
      peg$c23 = ">",
      peg$c24 = peg$literalExpectation(">", false),
      peg$c25 = function(value) {
        value.type = 'onestep'
        return value
      },
      peg$c26 = peg$otherExpectation("whitespace"),
      peg$c27 = /^[ \t\n\r ]/,
      peg$c28 = peg$classExpectation([" ", "\t", "\n", "\r", " "], false, false),
      peg$c29 = function() { return { type:'emoji', value:text() } },
      peg$c30 = peg$otherExpectation("emoji"),
      peg$c31 = "\uD83C",
      peg$c32 = peg$literalExpectation("\uD83C", false),
      peg$c33 = /^[\uDF00-\uDFFA]/,
      peg$c34 = peg$classExpectation([["\uDF00", "\uDFFA"]], false, false),
      peg$c35 = "\uD83D",
      peg$c36 = peg$literalExpectation("\uD83D", false),
      peg$c37 = /^[\uDC00-\uDDFF]/,
      peg$c38 = peg$classExpectation([["\uDC00", "\uDDFF"]], false, false),
      peg$c39 = "\uDF85",
      peg$c40 = peg$literalExpectation("\uDF85", false),
      peg$c41 = "\uDFC3",
      peg$c42 = peg$literalExpectation("\uDFC3", false),
      peg$c43 = "\uDFC4",
      peg$c44 = peg$literalExpectation("\uDFC4", false),
      peg$c45 = "\uDFCA",
      peg$c46 = peg$literalExpectation("\uDFCA", false),
      peg$c47 = "\uDFCB",
      peg$c48 = peg$literalExpectation("\uDFCB", false),
      peg$c49 = "\uDC42",
      peg$c50 = peg$literalExpectation("\uDC42", false),
      peg$c51 = "\uDC43",
      peg$c52 = peg$literalExpectation("\uDC43", false),
      peg$c53 = /^[\uDC46-\uDC50]/,
      peg$c54 = peg$classExpectation([["\uDC46", "\uDC50"]], false, false),
      peg$c55 = /^[\uDC66-\uDC69]/,
      peg$c56 = peg$classExpectation([["\uDC66", "\uDC69"]], false, false),
      peg$c57 = "\uDC6E",
      peg$c58 = peg$literalExpectation("\uDC6E", false),
      peg$c59 = /^[\uDC70-\uDC78]/,
      peg$c60 = peg$classExpectation([["\uDC70", "\uDC78"]], false, false),
      peg$c61 = "\uDC7C",
      peg$c62 = peg$literalExpectation("\uDC7C", false),
      peg$c63 = /^[\uDC81-\uDC83]/,
      peg$c64 = peg$classExpectation([["\uDC81", "\uDC83"]], false, false),
      peg$c65 = /^[\uDC85-\uDC87]/,
      peg$c66 = peg$classExpectation([["\uDC85", "\uDC87"]], false, false),
      peg$c67 = "\uDCAA",
      peg$c68 = peg$literalExpectation("\uDCAA", false),
      peg$c69 = "\uDD75",
      peg$c70 = peg$literalExpectation("\uDD75", false),
      peg$c71 = "\uDD90",
      peg$c72 = peg$literalExpectation("\uDD90", false),
      peg$c73 = "\uDD95",
      peg$c74 = peg$literalExpectation("\uDD95", false),
      peg$c75 = "\uDD96",
      peg$c76 = peg$literalExpectation("\uDD96", false),
      peg$c77 = "\uD83E",
      peg$c78 = peg$literalExpectation("\uD83E", false),
      peg$c79 = /^[\uDD00-\uDDFF]/,
      peg$c80 = peg$classExpectation([["\uDD00", "\uDDFF"]], false, false),
      peg$c81 = "\uDD18",
      peg$c82 = peg$literalExpectation("\uDD18", false),
      peg$c83 = /^[\uDE00-\uDE4F]/,
      peg$c84 = peg$classExpectation([["\uDE00", "\uDE4F"]], false, false),
      peg$c85 = /^[\uDE45-\uDE47]/,
      peg$c86 = peg$classExpectation([["\uDE45", "\uDE47"]], false, false),
      peg$c87 = /^[\uDE4B-\uDE4F]/,
      peg$c88 = peg$classExpectation([["\uDE4B", "\uDE4F"]], false, false),
      peg$c89 = /^[\uDE80-\uDEC5]/,
      peg$c90 = peg$classExpectation([["\uDE80", "\uDEC5"]], false, false),
      peg$c91 = /^[\uDECB-\uDED0]/,
      peg$c92 = peg$classExpectation([["\uDECB", "\uDED0"]], false, false),
      peg$c93 = /^[\uDEE0-\uDEE5]/,
      peg$c94 = peg$classExpectation([["\uDEE0", "\uDEE5"]], false, false),
      peg$c95 = "\uDEE9",
      peg$c96 = peg$literalExpectation("\uDEE9", false),
      peg$c97 = /^[\uDEEB-\uDEEC]/,
      peg$c98 = peg$classExpectation([["\uDEEB", "\uDEEC"]], false, false),
      peg$c99 = "\uDEF0",
      peg$c100 = peg$literalExpectation("\uDEF0", false),
      peg$c101 = "\uDEF3",
      peg$c102 = peg$literalExpectation("\uDEF3", false),
      peg$c103 = "\uDEA3",
      peg$c104 = peg$literalExpectation("\uDEA3", false),
      peg$c105 = /^[\uDEB4-\uDEB6]/,
      peg$c106 = peg$classExpectation([["\uDEB4", "\uDEB6"]], false, false),
      peg$c107 = "\uDEC0",
      peg$c108 = peg$literalExpectation("\uDEC0", false),
      peg$c109 = /^[\u2600-\u26FF]/,
      peg$c110 = peg$classExpectation([["\u2600", "\u26FF"]], false, false),
      peg$c111 = "\u261D",
      peg$c112 = peg$literalExpectation("\u261D", false),
      peg$c113 = "\u26F9",
      peg$c114 = peg$literalExpectation("\u26F9", false),
      peg$c115 = "\u2712",
      peg$c116 = peg$literalExpectation("\u2712", false),
      peg$c117 = "\u2714",
      peg$c118 = peg$literalExpectation("\u2714", false),
      peg$c119 = "\u2716",
      peg$c120 = peg$literalExpectation("\u2716", false),
      peg$c121 = "\u271D",
      peg$c122 = peg$literalExpectation("\u271D", false),
      peg$c123 = "\u2721",
      peg$c124 = peg$literalExpectation("\u2721", false),
      peg$c125 = "\u2728",
      peg$c126 = peg$literalExpectation("\u2728", false),
      peg$c127 = /^[\u2733-\u2734]/,
      peg$c128 = peg$classExpectation([["\u2733", "\u2734"]], false, false),
      peg$c129 = "\u2744",
      peg$c130 = peg$literalExpectation("\u2744", false),
      peg$c131 = "\u2747",
      peg$c132 = peg$literalExpectation("\u2747", false),
      peg$c133 = "\u274C",
      peg$c134 = peg$literalExpectation("\u274C", false),
      peg$c135 = "\u274E",
      peg$c136 = peg$literalExpectation("\u274E", false),
      peg$c137 = /^[\u2753-\u2755]/,
      peg$c138 = peg$classExpectation([["\u2753", "\u2755"]], false, false),
      peg$c139 = "\u2757",
      peg$c140 = peg$literalExpectation("\u2757", false),
      peg$c141 = /^[\u2763-\u2764]/,
      peg$c142 = peg$classExpectation([["\u2763", "\u2764"]], false, false),
      peg$c143 = /^[\u2795-\u2797]/,
      peg$c144 = peg$classExpectation([["\u2795", "\u2797"]], false, false),
      peg$c145 = "\u27A1",
      peg$c146 = peg$literalExpectation("\u27A1", false),
      peg$c147 = "\u27B0",
      peg$c148 = peg$literalExpectation("\u27B0", false),
      peg$c149 = "\u27BF",
      peg$c150 = peg$literalExpectation("\u27BF", false),
      peg$c151 = "\u2702",
      peg$c152 = peg$literalExpectation("\u2702", false),
      peg$c153 = "\u2705",
      peg$c154 = peg$literalExpectation("\u2705", false),
      peg$c155 = /^[\u2708-\u270D]/,
      peg$c156 = peg$classExpectation([["\u2708", "\u270D"]], false, false),
      peg$c157 = "\u270F",
      peg$c158 = peg$literalExpectation("\u270F", false),
      peg$c159 = /^[\u270A-\u270D]/,
      peg$c160 = peg$classExpectation([["\u270A", "\u270D"]], false, false),
      peg$c161 = /^[\u2194-\u2199]/,
      peg$c162 = peg$classExpectation([["\u2194", "\u2199"]], false, false),
      peg$c163 = /^[\u21A9-\u21AA]/,
      peg$c164 = peg$classExpectation([["\u21A9", "\u21AA"]], false, false),
      peg$c165 = "\u3030",
      peg$c166 = peg$literalExpectation("\u3030", false),
      peg$c167 = "\u303D",
      peg$c168 = peg$literalExpectation("\u303D", false),
      peg$c169 = /^[\uDD70-\uDD71]/,
      peg$c170 = peg$classExpectation([["\uDD70", "\uDD71"]], false, false),
      peg$c171 = /^[\uDD7E-\uDD7F]/,
      peg$c172 = peg$classExpectation([["\uDD7E", "\uDD7F"]], false, false),
      peg$c173 = "\uDD8E",
      peg$c174 = peg$literalExpectation("\uDD8E", false),
      peg$c175 = /^[\uDD91-\uDD9A]/,
      peg$c176 = peg$classExpectation([["\uDD91", "\uDD9A"]], false, false),
      peg$c177 = /^[\uDDE6-\uDDFF]/,
      peg$c178 = peg$classExpectation([["\uDDE6", "\uDDFF"]], false, false),
      peg$c179 = "\u24C2",
      peg$c180 = peg$literalExpectation("\u24C2", false),
      peg$c181 = "\u3297",
      peg$c182 = peg$literalExpectation("\u3297", false),
      peg$c183 = "\u3299",
      peg$c184 = peg$literalExpectation("\u3299", false),
      peg$c185 = /^[\uDE01-\uDE02]/,
      peg$c186 = peg$classExpectation([["\uDE01", "\uDE02"]], false, false),
      peg$c187 = "\uDE1A",
      peg$c188 = peg$literalExpectation("\uDE1A", false),
      peg$c189 = "\uDE2F",
      peg$c190 = peg$literalExpectation("\uDE2F", false),
      peg$c191 = /^[\uDE32-\uDE3A]/,
      peg$c192 = peg$classExpectation([["\uDE32", "\uDE3A"]], false, false),
      peg$c193 = /^[\uDE50-\uDE51]/,
      peg$c194 = peg$classExpectation([["\uDE50", "\uDE51"]], false, false),
      peg$c195 = "\u203C",
      peg$c196 = peg$literalExpectation("\u203C", false),
      peg$c197 = "\u2049",
      peg$c198 = peg$literalExpectation("\u2049", false),
      peg$c199 = /^[\u25AA-\u25AB]/,
      peg$c200 = peg$classExpectation([["\u25AA", "\u25AB"]], false, false),
      peg$c201 = "\u25B6",
      peg$c202 = peg$literalExpectation("\u25B6", false),
      peg$c203 = "\u25C0",
      peg$c204 = peg$literalExpectation("\u25C0", false),
      peg$c205 = /^[\u25FB-\u25FE]/,
      peg$c206 = peg$classExpectation([["\u25FB", "\u25FE"]], false, false),
      peg$c207 = "\u2122",
      peg$c208 = peg$literalExpectation("\u2122", false),
      peg$c209 = "\u2139",
      peg$c210 = peg$literalExpectation("\u2139", false),
      peg$c211 = "\uDC04",
      peg$c212 = peg$literalExpectation("\uDC04", false),
      peg$c213 = /^[\u2B05-\u2B07]/,
      peg$c214 = peg$classExpectation([["\u2B05", "\u2B07"]], false, false),
      peg$c215 = /^[\u2B1B-\u2B1C]/,
      peg$c216 = peg$classExpectation([["\u2B1B", "\u2B1C"]], false, false),
      peg$c217 = "\u2B50",
      peg$c218 = peg$literalExpectation("\u2B50", false),
      peg$c219 = "\u2B55",
      peg$c220 = peg$literalExpectation("\u2B55", false),
      peg$c221 = /^[\u231A-\u231B]/,
      peg$c222 = peg$classExpectation([["\u231A", "\u231B"]], false, false),
      peg$c223 = "\u2328",
      peg$c224 = peg$literalExpectation("\u2328", false),
      peg$c225 = "\u23CF",
      peg$c226 = peg$literalExpectation("\u23CF", false),
      peg$c227 = /^[\u23E9-\u23F3]/,
      peg$c228 = peg$classExpectation([["\u23E9", "\u23F3"]], false, false),
      peg$c229 = /^[\u23F8-\u23FA]/,
      peg$c230 = peg$classExpectation([["\u23F8", "\u23FA"]], false, false),
      peg$c231 = "\uDCCF",
      peg$c232 = peg$literalExpectation("\uDCCF", false),
      peg$c233 = /^[\u2934-\u2935]/,
      peg$c234 = peg$classExpectation([["\u2934", "\u2935"]], false, false),
      peg$c235 = /^[\uDFFB-\uDFFF]/,
      peg$c236 = peg$classExpectation([["\uDFFB", "\uDFFF"]], false, false),
      peg$c237 = peg$otherExpectation("Zero width joiner"),
      peg$c238 = "\u200D",
      peg$c239 = peg$literalExpectation("\u200D", false),
      peg$c240 = "\uFE0E",
      peg$c241 = peg$literalExpectation("\uFE0E", false),
      peg$c242 = "\uFE0F",
      peg$c243 = peg$literalExpectation("\uFE0F", false),
      peg$c244 = "\uDC68",
      peg$c245 = peg$literalExpectation("\uDC68", false),
      peg$c246 = "\uDC69",
      peg$c247 = peg$literalExpectation("\uDC69", false),
      peg$c248 = "\uDC66",
      peg$c249 = peg$literalExpectation("\uDC66", false),
      peg$c250 = "\uDC67",
      peg$c251 = peg$literalExpectation("\uDC67", false),
      peg$c252 = "\u2764",
      peg$c253 = peg$literalExpectation("\u2764", false),
      peg$c254 = "\uDC8B",
      peg$c255 = peg$literalExpectation("\uDC8B", false),
      peg$c256 = "\uDC41",
      peg$c257 = peg$literalExpectation("\uDC41", false),
      peg$c258 = "\uDDE8",
      peg$c259 = peg$literalExpectation("\uDDE8", false),
      peg$c260 = "?",
      peg$c261 = peg$literalExpectation("?", false),

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parsepattern() {
    var s0;

    s0 = peg$parselist();
    if (s0 === peg$FAILED) {
      s0 = peg$parsegroup();
      if (s0 === peg$FAILED) {
        s0 = peg$parsedegrade();
        if (s0 === peg$FAILED) {
          s0 = peg$parserepeat();
          if (s0 === peg$FAILED) {
            s0 = peg$parseonestep();
          }
        }
      }
    }

    return s0;
  }

  function peg$parseterm() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parsegroup();
    if (s1 === peg$FAILED) {
      s1 = peg$parsedegrade();
      if (s1 === peg$FAILED) {
        s1 = peg$parserepeat();
        if (s1 === peg$FAILED) {
          s1 = peg$parseemoji();
          if (s1 === peg$FAILED) {
            s1 = peg$parserest();
            if (s1 === peg$FAILED) {
              s1 = peg$parseonestep();
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c1(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c0); }
    }

    return s0;
  }

  function peg$parselist() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseterm();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseterm();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c2(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsegroup() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 91) {
        s2 = peg$c4;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c5); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseterm();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parseterm();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 93) {
                s6 = peg$c6;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c7); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c2(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c3); }
    }

    return s0;
  }

  function peg$parsedegrade() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsenotdegrade();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsequestion();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c8(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsenotdegrade() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parsegroup();
    if (s1 === peg$FAILED) {
      s1 = peg$parserepeat();
      if (s1 === peg$FAILED) {
        s1 = peg$parseemoji();
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c9(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsenumber() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parse_();
    if (s2 !== peg$FAILED) {
      s3 = [];
      if (peg$c10.test(input.charAt(peg$currPos))) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c11); }
      }
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          if (peg$c10.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c11); }
          }
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          s2 = [s2, s3, s4];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c12();
    }
    s0 = s1;

    return s0;
  }

  function peg$parserepeat() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsenotrepeat();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 42) {
        s2 = peg$c13;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c14); }
      }
      if (s2 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 47) {
          s2 = peg$c15;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c16); }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsenumber();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c17(s1, s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsenotrepeat() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parsegroup();
    if (s1 === peg$FAILED) {
      s1 = peg$parseemoji();
      if (s1 === peg$FAILED) {
        s1 = peg$parserest();
        if (s1 === peg$FAILED) {
          s1 = peg$parseonestep();
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c9(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserest() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 126) {
        s2 = peg$c18;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c19); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c20();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseonestep() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parse_();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 60) {
        s2 = peg$c21;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c22); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          s4 = peg$parselist();
          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 62) {
                s6 = peg$c23;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c24); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c25(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parse_() {
    var s0, s1;

    peg$silentFails++;
    s0 = [];
    if (peg$c27.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c28); }
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      if (peg$c27.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c28); }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c26); }
    }

    return s0;
  }

  function peg$parseemoji() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseEmoji_Raw();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEmoji_Variant();
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c29();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEmoji_Raw() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$parseMiscellaneous_Symbols_and_Pictographs();
    if (s0 === peg$FAILED) {
      s0 = peg$parseSupplemental_Symbols_and_Pictographs();
      if (s0 === peg$FAILED) {
        s0 = peg$parseEmoticons();
        if (s0 === peg$FAILED) {
          s0 = peg$parseTransport_and_Map_Symbols();
          if (s0 === peg$FAILED) {
            s0 = peg$parseMiscellaneous_Symbols();
            if (s0 === peg$FAILED) {
              s0 = peg$parseDingbats();
              if (s0 === peg$FAILED) {
                s0 = peg$parseArrows();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseCJK_Symbols_and_Punctuation();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseEnclosed_Alphanumeric_Supplement();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseEnclosed_Alphanumerics();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseEnclosed_Ideographic_Supplement();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseGeneral_Punctuation();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseGeometric_Shapes();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseLetterlike_Symbols();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseMahjong_Tiles();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseMiscellaneous_Symbols_and_Arrows();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parseMiscellaneous_Technical();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parsePlaying_cards();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parseSupplemental_ArrowsB();
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c30); }
    }

    return s0;
  }

  function peg$parseMiscellaneous_Symbols_and_Pictographs() {
    var s0, s1, s2;

    s0 = peg$parseMiscellaneous_Symbols_and_Pictographs_Sequences();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseMiscellaneous_Symbols_and_Pictographs_Diversity();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseFitzpatrick_Modifier();
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 55356) {
          s1 = peg$c31;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c32); }
        }
        if (s1 !== peg$FAILED) {
          if (peg$c33.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c34); }
          }
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 55357) {
            s1 = peg$c35;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c36); }
          }
          if (s1 !== peg$FAILED) {
            if (peg$c37.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c38); }
            }
            if (s2 !== peg$FAILED) {
              s1 = [s1, s2];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    return s0;
  }

  function peg$parseMiscellaneous_Symbols_and_Pictographs_Sequences() {
    var s0;

    s0 = peg$parseKiss_Sequence();
    if (s0 === peg$FAILED) {
      s0 = peg$parseCouple_With_Heart_Sequence();
      if (s0 === peg$FAILED) {
        s0 = peg$parseFamily_Sequence();
        if (s0 === peg$FAILED) {
          s0 = peg$parseLeft_Eye_Speech_Bubble_Sequence();
        }
      }
    }

    return s0;
  }

  function peg$parseMiscellaneous_Symbols_and_Pictographs_Diversity() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55356) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 57221) {
        s2 = peg$c39;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c40); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55356) {
        s1 = peg$c31;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c32); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 57283) {
          s2 = peg$c41;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c42); }
        }
        if (s2 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 57284) {
            s2 = peg$c43;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c44); }
          }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 55356) {
          s1 = peg$c31;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c32); }
        }
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 57290) {
            s2 = peg$c45;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c46); }
          }
          if (s2 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 57291) {
              s2 = peg$c47;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c48); }
            }
          }
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 55357) {
            s1 = peg$c35;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c36); }
          }
          if (s1 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 56386) {
              s2 = peg$c49;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c50); }
            }
            if (s2 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 56387) {
                s2 = peg$c51;
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c52); }
              }
            }
            if (s2 !== peg$FAILED) {
              s1 = [s1, s2];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 55357) {
              s1 = peg$c35;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c36); }
            }
            if (s1 !== peg$FAILED) {
              if (peg$c53.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c54); }
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 55357) {
                s1 = peg$c35;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c36); }
              }
              if (s1 !== peg$FAILED) {
                if (peg$c55.test(input.charAt(peg$currPos))) {
                  s2 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c56); }
                }
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 55357) {
                  s1 = peg$c35;
                  peg$currPos++;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                }
                if (s1 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 56430) {
                    s2 = peg$c57;
                    peg$currPos++;
                  } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c58); }
                  }
                  if (s2 !== peg$FAILED) {
                    s1 = [s1, s2];
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 55357) {
                    s1 = peg$c35;
                    peg$currPos++;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c36); }
                  }
                  if (s1 !== peg$FAILED) {
                    if (peg$c59.test(input.charAt(peg$currPos))) {
                      s2 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c60); }
                    }
                    if (s2 !== peg$FAILED) {
                      s1 = [s1, s2];
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 55357) {
                      s1 = peg$c35;
                      peg$currPos++;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c36); }
                    }
                    if (s1 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 56444) {
                        s2 = peg$c61;
                        peg$currPos++;
                      } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c62); }
                      }
                      if (s2 !== peg$FAILED) {
                        s1 = [s1, s2];
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      if (input.charCodeAt(peg$currPos) === 55357) {
                        s1 = peg$c35;
                        peg$currPos++;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c36); }
                      }
                      if (s1 !== peg$FAILED) {
                        if (peg$c63.test(input.charAt(peg$currPos))) {
                          s2 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s2 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c64); }
                        }
                        if (s2 !== peg$FAILED) {
                          s1 = [s1, s2];
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 55357) {
                          s1 = peg$c35;
                          peg$currPos++;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c36); }
                        }
                        if (s1 !== peg$FAILED) {
                          if (peg$c65.test(input.charAt(peg$currPos))) {
                            s2 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c66); }
                          }
                          if (s2 !== peg$FAILED) {
                            s1 = [s1, s2];
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                        if (s0 === peg$FAILED) {
                          s0 = peg$currPos;
                          if (input.charCodeAt(peg$currPos) === 55357) {
                            s1 = peg$c35;
                            peg$currPos++;
                          } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c36); }
                          }
                          if (s1 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 56490) {
                              s2 = peg$c67;
                              peg$currPos++;
                            } else {
                              s2 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c68); }
                            }
                            if (s2 !== peg$FAILED) {
                              s1 = [s1, s2];
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                          if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 55357) {
                              s1 = peg$c35;
                              peg$currPos++;
                            } else {
                              s1 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c36); }
                            }
                            if (s1 !== peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 56693) {
                                s2 = peg$c69;
                                peg$currPos++;
                              } else {
                                s2 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c70); }
                              }
                              if (s2 !== peg$FAILED) {
                                s1 = [s1, s2];
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                            if (s0 === peg$FAILED) {
                              s0 = peg$currPos;
                              if (input.charCodeAt(peg$currPos) === 55357) {
                                s1 = peg$c35;
                                peg$currPos++;
                              } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c36); }
                              }
                              if (s1 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 56720) {
                                  s2 = peg$c71;
                                  peg$currPos++;
                                } else {
                                  s2 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c72); }
                                }
                                if (s2 !== peg$FAILED) {
                                  s1 = [s1, s2];
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                              if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.charCodeAt(peg$currPos) === 55357) {
                                  s1 = peg$c35;
                                  peg$currPos++;
                                } else {
                                  s1 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                                }
                                if (s1 !== peg$FAILED) {
                                  if (input.charCodeAt(peg$currPos) === 56725) {
                                    s2 = peg$c73;
                                    peg$currPos++;
                                  } else {
                                    s2 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c74); }
                                  }
                                  if (s2 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 56726) {
                                      s2 = peg$c75;
                                      peg$currPos++;
                                    } else {
                                      s2 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c76); }
                                    }
                                  }
                                  if (s2 !== peg$FAILED) {
                                    s1 = [s1, s2];
                                    s0 = s1;
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseSupplemental_Symbols_and_Pictographs() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseSupplemental_Symbols_and_Pictographs_Diversity();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFitzpatrick_Modifier();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55358) {
        s1 = peg$c77;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c78); }
      }
      if (s1 !== peg$FAILED) {
        if (peg$c79.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c80); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseSupplemental_Symbols_and_Pictographs_Diversity() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55358) {
      s1 = peg$c77;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c78); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56600) {
        s2 = peg$c81;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c82); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEmoticons() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseEmoticons_Diversity();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFitzpatrick_Modifier();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55357) {
        s1 = peg$c35;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c36); }
      }
      if (s1 !== peg$FAILED) {
        if (peg$c83.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c84); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseEmoticons_Diversity() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55357) {
      s1 = peg$c35;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 !== peg$FAILED) {
      if (peg$c85.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c86); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55357) {
        s1 = peg$c35;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c36); }
      }
      if (s1 !== peg$FAILED) {
        if (peg$c87.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c88); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseTransport_and_Map_Symbols() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseTransport_and_Map_Symbols_Diversity();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFitzpatrick_Modifier();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55357) {
        s1 = peg$c35;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c36); }
      }
      if (s1 !== peg$FAILED) {
        if (peg$c89.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c90); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 55357) {
          s1 = peg$c35;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c36); }
        }
        if (s1 !== peg$FAILED) {
          if (peg$c91.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c92); }
          }
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 55357) {
            s1 = peg$c35;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c36); }
          }
          if (s1 !== peg$FAILED) {
            if (peg$c93.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c94); }
            }
            if (s2 !== peg$FAILED) {
              s1 = [s1, s2];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 55357) {
              s1 = peg$c35;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c36); }
            }
            if (s1 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 57065) {
                s2 = peg$c95;
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c96); }
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 55357) {
                s1 = peg$c35;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c36); }
              }
              if (s1 !== peg$FAILED) {
                if (peg$c97.test(input.charAt(peg$currPos))) {
                  s2 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c98); }
                }
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 55357) {
                  s1 = peg$c35;
                  peg$currPos++;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                }
                if (s1 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 57072) {
                    s2 = peg$c99;
                    peg$currPos++;
                  } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c100); }
                  }
                  if (s2 !== peg$FAILED) {
                    s1 = [s1, s2];
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.charCodeAt(peg$currPos) === 55357) {
                    s1 = peg$c35;
                    peg$currPos++;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c36); }
                  }
                  if (s1 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 57075) {
                      s2 = peg$c101;
                      peg$currPos++;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c102); }
                    }
                    if (s2 !== peg$FAILED) {
                      s1 = [s1, s2];
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseTransport_and_Map_Symbols_Diversity() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55357) {
      s1 = peg$c35;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56995) {
        s2 = peg$c103;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c104); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55357) {
        s1 = peg$c35;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c36); }
      }
      if (s1 !== peg$FAILED) {
        if (peg$c105.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c106); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 55357) {
          s1 = peg$c35;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c36); }
        }
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 57024) {
            s2 = peg$c107;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c108); }
          }
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parseMiscellaneous_Symbols() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseMiscellaneous_Symbols_Diversity();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFitzpatrick_Modifier();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (peg$c109.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c110); }
      }
    }

    return s0;
  }

  function peg$parseMiscellaneous_Symbols_Diversity() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 9757) {
      s0 = peg$c111;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c112); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 9977) {
        s0 = peg$c113;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c114); }
      }
    }

    return s0;
  }

  function peg$parseDingbats() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseDingbats_Diversity();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseFitzpatrick_Modifier();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 10002) {
        s0 = peg$c115;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c116); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 10004) {
          s0 = peg$c117;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c118); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 10006) {
            s0 = peg$c119;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c120); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 10013) {
              s0 = peg$c121;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c122); }
            }
            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 10017) {
                s0 = peg$c123;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c124); }
              }
              if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 10024) {
                  s0 = peg$c125;
                  peg$currPos++;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c126); }
                }
                if (s0 === peg$FAILED) {
                  if (peg$c127.test(input.charAt(peg$currPos))) {
                    s0 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c128); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 10052) {
                      s0 = peg$c129;
                      peg$currPos++;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c130); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 10055) {
                        s0 = peg$c131;
                        peg$currPos++;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c132); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 10060) {
                          s0 = peg$c133;
                          peg$currPos++;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c134); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 10062) {
                            s0 = peg$c135;
                            peg$currPos++;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c136); }
                          }
                          if (s0 === peg$FAILED) {
                            if (peg$c137.test(input.charAt(peg$currPos))) {
                              s0 = input.charAt(peg$currPos);
                              peg$currPos++;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c138); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 10071) {
                                s0 = peg$c139;
                                peg$currPos++;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c140); }
                              }
                              if (s0 === peg$FAILED) {
                                if (peg$c141.test(input.charAt(peg$currPos))) {
                                  s0 = input.charAt(peg$currPos);
                                  peg$currPos++;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c142); }
                                }
                                if (s0 === peg$FAILED) {
                                  if (peg$c143.test(input.charAt(peg$currPos))) {
                                    s0 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                  } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c144); }
                                  }
                                  if (s0 === peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 10145) {
                                      s0 = peg$c145;
                                      peg$currPos++;
                                    } else {
                                      s0 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c146); }
                                    }
                                    if (s0 === peg$FAILED) {
                                      if (input.charCodeAt(peg$currPos) === 10160) {
                                        s0 = peg$c147;
                                        peg$currPos++;
                                      } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c148); }
                                      }
                                      if (s0 === peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 10175) {
                                          s0 = peg$c149;
                                          peg$currPos++;
                                        } else {
                                          s0 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c150); }
                                        }
                                        if (s0 === peg$FAILED) {
                                          if (input.charCodeAt(peg$currPos) === 9986) {
                                            s0 = peg$c151;
                                            peg$currPos++;
                                          } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c152); }
                                          }
                                          if (s0 === peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 9989) {
                                              s0 = peg$c153;
                                              peg$currPos++;
                                            } else {
                                              s0 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c154); }
                                            }
                                            if (s0 === peg$FAILED) {
                                              if (peg$c155.test(input.charAt(peg$currPos))) {
                                                s0 = input.charAt(peg$currPos);
                                                peg$currPos++;
                                              } else {
                                                s0 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c156); }
                                              }
                                              if (s0 === peg$FAILED) {
                                                if (input.charCodeAt(peg$currPos) === 9999) {
                                                  s0 = peg$c157;
                                                  peg$currPos++;
                                                } else {
                                                  s0 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c158); }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseDingbats_Diversity() {
    var s0;

    if (peg$c159.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c160); }
    }

    return s0;
  }

  function peg$parseArrows() {
    var s0;

    if (peg$c161.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c162); }
    }
    if (s0 === peg$FAILED) {
      if (peg$c163.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c164); }
      }
    }

    return s0;
  }

  function peg$parseCJK_Symbols_and_Punctuation() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 12336) {
      s0 = peg$c165;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c166); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 12349) {
        s0 = peg$c167;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c168); }
      }
    }

    return s0;
  }

  function peg$parseEnclosed_Alphanumeric_Supplement() {
    var s0, s1, s2;

    s0 = peg$parseRegional_Indicator_Symbol();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55356) {
        s1 = peg$c31;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c32); }
      }
      if (s1 !== peg$FAILED) {
        if (peg$c169.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c170); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 55356) {
          s1 = peg$c31;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c32); }
        }
        if (s1 !== peg$FAILED) {
          if (peg$c171.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c172); }
          }
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 55356) {
            s1 = peg$c31;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c32); }
          }
          if (s1 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 56718) {
              s2 = peg$c173;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c174); }
            }
            if (s2 !== peg$FAILED) {
              s1 = [s1, s2];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 55356) {
              s1 = peg$c31;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c32); }
            }
            if (s1 !== peg$FAILED) {
              if (peg$c175.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c176); }
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 55356) {
                s1 = peg$c31;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c32); }
              }
              if (s1 !== peg$FAILED) {
                if (peg$c177.test(input.charAt(peg$currPos))) {
                  s2 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c178); }
                }
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseRegional_Indicator_Symbol() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55356) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 !== peg$FAILED) {
      if (peg$c177.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c178); }
      }
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 55356) {
          s3 = peg$c31;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c32); }
        }
        if (s3 !== peg$FAILED) {
          if (peg$c177.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c178); }
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEnclosed_Alphanumerics() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 9410) {
      s0 = peg$c179;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c180); }
    }

    return s0;
  }

  function peg$parseEnclosed_CJK_Letters_and_Months() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 12951) {
      s0 = peg$c181;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c182); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 12953) {
        s0 = peg$c183;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c184); }
      }
    }

    return s0;
  }

  function peg$parseEnclosed_Ideographic_Supplement() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55356) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 !== peg$FAILED) {
      if (peg$c185.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c186); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 55356) {
        s1 = peg$c31;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c32); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 56858) {
          s2 = peg$c187;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c188); }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 55356) {
          s1 = peg$c31;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c32); }
        }
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 56879) {
            s2 = peg$c189;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c190); }
          }
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 55356) {
            s1 = peg$c31;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c32); }
          }
          if (s1 !== peg$FAILED) {
            if (peg$c191.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c192); }
            }
            if (s2 !== peg$FAILED) {
              s1 = [s1, s2];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 55356) {
              s1 = peg$c31;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c32); }
            }
            if (s1 !== peg$FAILED) {
              if (peg$c193.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c194); }
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseGeneral_Punctuation() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 8252) {
      s0 = peg$c195;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c196); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 8265) {
        s0 = peg$c197;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c198); }
      }
    }

    return s0;
  }

  function peg$parseGeometric_Shapes() {
    var s0;

    if (peg$c199.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c200); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 9654) {
        s0 = peg$c201;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c202); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 9664) {
          s0 = peg$c203;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c204); }
        }
        if (s0 === peg$FAILED) {
          if (peg$c205.test(input.charAt(peg$currPos))) {
            s0 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c206); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseLetterlike_Symbols() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 8482) {
      s0 = peg$c207;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c208); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 8505) {
        s0 = peg$c209;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c210); }
      }
    }

    return s0;
  }

  function peg$parseMahjong_Tiles() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55356) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56324) {
        s2 = peg$c211;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c212); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMiscellaneous_Symbols_and_Arrows() {
    var s0;

    if (peg$c213.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c214); }
    }
    if (s0 === peg$FAILED) {
      if (peg$c215.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c216); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 11088) {
          s0 = peg$c217;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c218); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 11093) {
            s0 = peg$c219;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c220); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseMiscellaneous_Technical() {
    var s0;

    if (peg$c221.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c222); }
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 9000) {
        s0 = peg$c223;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c224); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 9167) {
          s0 = peg$c225;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c226); }
        }
        if (s0 === peg$FAILED) {
          if (peg$c227.test(input.charAt(peg$currPos))) {
            s0 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c228); }
          }
          if (s0 === peg$FAILED) {
            if (peg$c229.test(input.charAt(peg$currPos))) {
              s0 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c230); }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsePlaying_cards() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55356) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56527) {
        s2 = peg$c231;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c232); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSupplemental_ArrowsB() {
    var s0;

    if (peg$c233.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c234); }
    }

    return s0;
  }

  function peg$parseFitzpatrick_Modifier() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55356) {
      s1 = peg$c31;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c32); }
    }
    if (s1 !== peg$FAILED) {
      if (peg$c235.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c236); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsezero() {
    var s0, s1;

    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 8205) {
      s0 = peg$c238;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c239); }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c237); }
    }

    return s0;
  }

  function peg$parseText_Variant() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 65038) {
      s0 = peg$c240;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c241); }
    }

    return s0;
  }

  function peg$parseEmoji_Variant() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 65039) {
      s0 = peg$c242;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c243); }
    }

    return s0;
  }

  function peg$parseManOrWoman() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55357) {
      s1 = peg$c35;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56424) {
        s2 = peg$c244;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c245); }
      }
      if (s2 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 56425) {
          s2 = peg$c246;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c247); }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseFitzpatrick_Modifier();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBoyOrGirl() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55357) {
      s1 = peg$c35;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56422) {
        s2 = peg$c248;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c249); }
      }
      if (s2 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 56423) {
          s2 = peg$c250;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c251); }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseFitzpatrick_Modifier();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseHeart() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 10084) {
      s1 = peg$c252;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c253); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseEmoji_Variant();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseKiss() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55357) {
      s1 = peg$c35;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56459) {
        s2 = peg$c254;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c255); }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseKiss_Sequence() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseManOrWoman();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsezero();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseHeart();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsezero();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseKiss();
            if (s5 !== peg$FAILED) {
              s6 = peg$parsezero();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseManOrWoman();
                if (s7 !== peg$FAILED) {
                  s1 = [s1, s2, s3, s4, s5, s6, s7];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCouple_With_Heart_Sequence() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseManOrWoman();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsezero();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseHeart();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsezero();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseManOrWoman();
            if (s5 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4, s5];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFamily_Sequence() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseManOrWoman();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$parsezero();
      if (s3 !== peg$FAILED) {
        s4 = peg$parseManOrWoman();
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsezero();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseBoyOrGirl();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parsezero();
            if (s6 !== peg$FAILED) {
              s7 = peg$parseBoyOrGirl();
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4, s5];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLeft_Eye_Speech_Bubble_Sequence() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 55357) {
      s1 = peg$c35;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c36); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 56385) {
        s2 = peg$c256;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c257); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsezero();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 55357) {
            s4 = peg$c35;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c36); }
          }
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 56808) {
              s5 = peg$c258;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c259); }
            }
            if (s5 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4, s5];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsequestion() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 63) {
      s0 = peg$c260;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c261); }
    }

    return s0;
  }


    var Fraction = require('fraction.js');

    function parseToObject(body, start = new Fraction(0), end = new Fraction(1)){

      let cycleBody = {};
      let cycleSection = start;
      let frac = new Fraction(end/body.length);

      for( let i = 0; i < body.length; i++ ) {
        cycleBody[cycleSection.toFraction(false)] = body[i];
        cycleSection = new Fraction(cycleSection).add(frac);
      }
      return cycleBody;
    }


  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};
